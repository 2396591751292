<template>
  <div>
    <v-carousel height="250" cycle touchless hide-delimiters hide-delimiter-background :show-arrows="false">
      <v-carousel-item
        v-for="(item, i) in viewmodel.images"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <v-container fluid>
      <h2 class="d-flex justify-center">Angebotsübersicht</h2>
      <v-row>
        <v-col cols="12">
          <v-data-table
            item-key="id"
            :headers="viewmodel.data_tables.courses.headers"
            :items="viewmodel.data_tables.courses.data"
          >
            <template v-slot:item.action="{ item }">
              <a href="#course_selection" @click="SetCourse(item.id)" v-if="item.free_slots > 0">Buchen</a>
            </template>
            <template v-slot:item.description="{ item }">
              <a :href="item.description" target="blank" v-if="item.description">Details</a>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" v-if="1 === 2"> Click Me </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline grey lighten-2"> Details </v-card-title>

          <v-card-text class="mt-4">
            {{ dialog_content }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialog = false"> Schließen </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <h2 v-if="Selected === true">Anmeldung</h2>
      <v-form ref="form_anmeldung" v-if="Selected === true">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-autocomplete
              readonly
              id="course_selection"
              label="Angebot"
              item-text="kurs_name"
              item-value="id"
              :items="this.viewmodel.data_tables.courses.data"
              v-model="viewmodel.form_data.kurs_id"
              :rules="viewmodel.form_data.rules.autocomplete_rules"
              @change="GetCourseDetails"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ === 'Ferienspiele'">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h3>Wunschtermin</h3>
            <v-date-picker
              v-model="viewmodel.form_data.date"
              full-width
              :min="viewmodel.course_data.date_from"
              :max="viewmodel.course_data.date_to"
            ></v-date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Vor- und Nachname"
              v-model="viewmodel.form_data.name_parent"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Anschrift"
              v-model="viewmodel.form_data.address_parent"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="PLZ"
              v-model="viewmodel.form_data.plz_parent"
              clearable
              :rules="viewmodel.form_data.rules.plz_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Stadt"
              v-model="viewmodel.form_data.city_parent"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="E-Mail"
              v-model="viewmodel.form_data.mail_parent"
              clearable
              :rules="viewmodel.form_data.rules.mail_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Telefonnummer"
              v-model="viewmodel.form_data.phone_parent"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ !== 'Schwimmkurs Erwachsene'">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Vor- und Nachname des Kindes"
              v-model="viewmodel.form_data.name_child"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ !== 'Schwimmkurs Erwachsene'">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-checkbox label="Anschrift identisch wie Elternteil" v-model="viewmodel.form_data.is_identical">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ !== 'Schwimmkurs Erwachsene' && !viewmodel.form_data.is_identical">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Anschrift des Kindes"
              v-model="viewmodel.form_data.address_child"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ !== 'Schwimmkurs Erwachsene' && !viewmodel.form_data.is_identical">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="PLZ"
              v-model="viewmodel.form_data.plz_child"
              clearable
              :rules="viewmodel.form_data.rules.plz_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ !== 'Schwimmkurs Erwachsene' && !viewmodel.form_data.is_identical">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Stadt"
              v-model="viewmodel.form_data.city_child"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="viewmodel.course_data.kurs_typ !== 'Schwimmkurs Erwachsene'">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Alter des Kindes"
              v-model="viewmodel.form_data.age_child"
              clearable
              :rules="viewmodel.form_data.rules.age_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-textarea label="Kommentar" v-model="viewmodel.form_data.comment" clearable counter="1000"> </v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="IsGutschein">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Gutscheincode"
              v-model="viewmodel.form_data.gutschein"
              clearable
              :rules="viewmodel.form_data.rules.generic_rules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox v-model="datenschutz_accepted">
              <div slot="label">
                Ich habe die Hinweise zum
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a target="_blank" href="https://www.sport-in-marl.de/datenschutzerklaerung" @click.stop v-on="on">
                      Datenschutz
                    </a>
                  </template>
                  Datenschutzerklärung
                </v-tooltip>
                gelesen und stimme der Verarbeitung meiner Daten zu.
              </div>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox v-model="foto_accepted">
              <div slot="label">
                Ich bestätige, dass während der Veranstaltung ggfs. Foto/Videomaterial von mir oder den von mir
                angemeldeten Personen aufgenommen werden darf und dazu das Einverständis aller erziehungsberechtigten
                Personen vorliegt. Ich bin weiterhin damit einverstanden, dass dieses zu Werbezwecken des Vereins/des
                SSV Marl in den sozialen Medien (Facebook, Instagram) oder zur Veröffentlichung in der lokalen Presse
                (Medienhaus Bauer) verwendet werden darf.
              </div>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <vue-recaptcha :sitekey="Sitekey" v-on:verify="VerifyCaptcha"></vue-recaptcha>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-btn
              @click.prevent="SubmitCourseRequest"
              color="success"
              :disabled="!state.c_verify || !datenschutz_accepted || !foto_accepted"
              >Abschicken</v-btn
            >
            <v-btn class="ml-2" @click.prevent="ResetForm" color="error">Abbrechen</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-alert
        class="mt-4"
        border="top"
        transition="fade-transition"
        dismissible
        colored-border
        :type="viewmodel.alert.alert_type"
        elevation="2"
        v-model="viewmodel.alert.show"
        >{{ viewmodel.alert.response }}</v-alert
      >
      <v-row
        ><v-col cols="12">
          <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="state.is_loading">
          </v-progress-linear></v-col
      ></v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require('axios');

import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Home',
  components: { VueRecaptcha },
  data: () => ({
    dialog: false,
    dialog_content: '',
    datenschutz_accepted: false,
    foto_accepted: false,
    datenschutz_label: 'Ich habe die Hinweise zum <a href="test.de">Test</a> gelesen',
    state: {
      c_verify: false,
      is_loading: false,
    },
    viewmodel: {
      alert: {
        show: false,
        alert_type: 'info',
        response: null,
      },
      course_data: {},
      form_data: {
        kurs_id: null,
        kurstyp: null,
        kurs_name: null,
        vorgang_id: null,
        date: null,
        name_parent: null,
        address_parent: null,
        plz_parent: null,
        city_parent: null,
        mail_parent: null,
        phone_parent: null,
        name_child: null,
        is_identical: true,
        address_child: null,
        plz_child: null,
        city_child: null,
        age_child: null,
        comment: null,
        gutschein: null,
        rules: {
          mail_rules: [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Keine gültige E-Mail Adresse'],
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          plz_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 5) || 'Falsche Anzahl von Zeichen (ungleich 5)',
          ],
          age_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => v >= 0 || 'Ungültiges Alter',
          ],
        },
      },
      images: [
        {
          src: 'https://kurse.sport-in-marl.de/img/mother-1039765_1920.jpg',
        },
        {
          src: 'https://kurse.sport-in-marl.de/img/children-1822688_1920.jpg',
        },
        {
          src: 'https://kurse.sport-in-marl.de/img/babington-1441535_1920.jpg',
        },
        {
          src: 'https://kurse.sport-in-marl.de/img/trampoline-2227667_1920.jpg',
        },
        {
          src: 'https://kurse.sport-in-marl.de/img/youth-2436343_1920.jpg',
        },
        {
          src: 'https://kurse.sport-in-marl.de/img/young-swimmer-2494904_1920.jpg',
        },
        {
          src: 'https://kurse.sport-in-marl.de/img/family-2742737_1920.jpg',
        },
      ],
      data_tables: {
        courses: {
          data: [],
          headers: [
            {
              text: 'Kurs/Veranstaltung',
              align: 'start',
              value: 'kurs_name',
            },
            {
              text: 'Kurstyp',
              align: 'start',
              value: 'kurs_typ',
            },
            {
              text: 'Beschreibung',
              align: 'start',
              value: 'description',
            },
            {
              text: 'Altersgruppe',
              align: 'start',
              value: 'age_group',
            },
            {
              text: 'Beginn',
              align: 'start',
              value: 'date_from',
            },
            {
              text: 'Ende',
              align: 'start',
              value: 'date_to',
            },
            {
              text: 'Dauer (Tage)',
              align: 'start',
              value: 'duration',
            },
            {
              text: 'Freie Plätze',
              align: 'start',
              value: 'free_slots',
            },
            {
              text: 'Maximale Teilnehmerzahl',
              align: 'start',
              value: 'capacity',
            },
            {
              text: 'Aktion',
              align: 'center',
              value: 'action',
            },
          ],
        },
      },
    },
  }),
  computed: {
    Sitekey() {
      return process.env.VUE_APP_SITEKEY;
    },
    Selected() {
      if (this.viewmodel.form_data.kurs_id) {
        return true;
      }
      return false;
    },
    IsGutschein() {
      if (this.viewmodel.course_data.kurs_typ !== '') {
        if (this.viewmodel.course_data.kurs_typ.includes('Schwimm')) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    VerifyCaptcha() {
      this.state.c_verify = true;
    },
    ShowModal(item) {
      this.dialog = true;
      this.dialog_content = item.description;
    },
    SetCourse(id) {
      this.viewmodel.form_data.kurs_id = id;
      this.GetCourseDetails();
      this.viewmodel.alert.show = false;
    },
    ResetForm() {
      this.$refs.form_anmeldung.reset();
      this.state.c_verify = false;
    },
    GetAllCourses() {
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/get_all_courses.php')
        .then((response) => {
          this.viewmodel.data_tables.courses.data = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCourseDetails() {
      if (this.viewmodel.form_data.kurs_id) {
        const data = {
          kurs_id: this.viewmodel.form_data.kurs_id,
        };
        axios
          .post(process.env.VUE_APP_APIBASEURL + '/get_course_details.php', data)
          .then((response) => {
            this.viewmodel.course_data = response.data.result[0];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    SubmitCourseRequest() {
      if (this.$refs.form_anmeldung.validate()) {
        let data = this.viewmodel.form_data;
        data.vorgang_id = parseInt(Math.random().toFixed(10).replace('0.', '')).toString();
        data.kurs_name = this.viewmodel.course_data.kurs_name;
        data.kurs_typ = this.viewmodel.course_data.kurs_typ;
        this.state.is_loading = true;
        this.viewmodel.alert.show = false;
        axios
          .post(process.env.VUE_APP_APIBASEURL + '/request_course.php', data)
          .then((response) => {
            this.viewmodel.alert.alert_type = 'success';
            this.viewmodel.alert.response = response.data.message;
            this.state.is_loading = false;
            this.$refs.form_anmeldung.reset();
            this.GetAllCourses();
          })
          .catch((error) => {
            this.state.is_loading = false;
            this.viewmodel.alert.show = true;
            this.viewmodel.alert.alert_type = 'error';
            this.viewmodel.alert.response = error.response.data.message;
          });
      }
    },
  },
  mounted() {
    this.GetAllCourses();
  },
};
</script>
