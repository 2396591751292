import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/SiM',
    name: 'SiM',
    beforeEnter() {
      location.href = 'https://sport-in-marl.de';
    },
  },
  {
    path: '/Vereine',
    name: 'Vereine',
    beforeEnter() {
      location.href = 'https://vereine.sport-in-marl.de';
    },
  },
  {
    path: '/Integration',
    name: 'Integration',
    beforeEnter() {
      location.href = 'https://sport-in-marl.de/integration';
    },
  },
  {
    path: '/Sportboxen',
    name: 'Sportboxen',
    beforeEnter() {
      location.href = 'https://sport-in-marl.de/sportboxen';
    },
  },
  {
    path: '/Kontakt',
    name: 'Kontakt',
    beforeEnter() {
      location.href = 'https://sport-in-marl.de/kontakt';
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Admin.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
