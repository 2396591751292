<template>
  <v-app>
    <v-app-bar color="primary" dark shrink-on-scroll prominent app class="opacity">
      <div class="d-flex flex-row align-self-center">
        <v-img
          src="https://www.sport-in-marl.de/wp-content/uploads/2017/07/sportinmarl_logo-1.png"
          max-width="250"
        ></v-img>
      </div>
      <div class="d-none d-sm-none d-md-none d-lg-flex d-xl-flex flex-grow-1 align-self-center justify-center">
        <router-link :to="{ name: 'SiM' }" class="text-decoration-none">
          <v-btn text x-large color="white" class="ml-4">Home </v-btn>
        </router-link>
        <router-link :to="{ name: 'Vereine' }" class="text-decoration-none">
          <v-btn text x-large color="white" class="ml-4">Sportarten und Vereine </v-btn>
        </router-link>
        <router-link :to="{ name: 'Home' }" class="text-decoration-none">
          <v-btn text x-large color="white" class="ml-4" active-class="secondary--text"
            >Sport- und Freizeitkurse
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'Integration' }" class="text-decoration-none">
          <v-btn text x-large color="white" class="ml-4" active-class="secondary--text">Integration </v-btn>
        </router-link>
        <router-link :to="{ name: 'Sportboxen' }" class="text-decoration-none">
          <v-btn text x-large color="white" class="ml-4" active-class="secondary--text">Sportboxen </v-btn>
        </router-link>
        <router-link :to="{ name: 'Kontakt' }" class="text-decoration-none">
          <v-btn text x-large color="white" class="ml-4" active-class="secondary--text">Über uns </v-btn>
        </router-link>
        <v-spacer></v-spacer>

        <!-- Spracheinstellungen -->
        <!-- <v-btn text x-large color="white" class="ml-4" active-class="secondary--text">
          <div class="flag-icon flag-icon-de"></div>
        </v-btn> -->
      </div>
      <div class="d-flex d-lg-none d-xl-none flex-grow-1 align-self-center justify-end">
        <v-app-bar-nav-icon x-large @click="showDrawer = true"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="showDrawer" dark color="primary" fixed bottom temporary>
      <v-list nav dense>
        <div class="d-flex flex-column flex-grow-1 align-self-center justify-center">
          <v-btn
            text
            color="white"
            class="mt-4"
            exact-active-class="secondary--text"
            v-for="(route, i) in routes"
            :key="i"
            :to="{ name: route.name }"
          >
            {{ route.text }}</v-btn
          >
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: { Footer },
  data: () => ({
    showDrawer: false,
    routes: [
      { text: 'Home', name: 'SiM', icon: 'mdi-home' },
      { text: 'Sportarten und Vereine', name: 'Vereine', icon: 'mdi-login' },
      { text: 'Sport- und Freizeitkurse', name: 'Home', icon: 'mdi-login' },
      { text: 'Integration', name: 'Integration', icon: 'mdi-login' },
      { text: 'Sportboxen', name: 'Sportboxen', icon: 'mdi-login' },
      { text: 'Über uns', name: 'Kontakt', icon: 'mdi-login' },
    ],
  }),
};
</script>

<style scoped>
.opacity {
  opacity: 1;
}
</style>
